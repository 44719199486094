@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("") */

@layer utilities {
    .cos-container {
        @apply px-4 sm:px-8 md:px-10 bscreen:px-14 lg:px-20
    }

    .btn-fill {
        @apply px-2 md:px-6 py-1 md:py-2 bg-primary rounded-lg text-white text-center
    }

    .btn-round {
        @apply w-6 h-6 sm:w-8 sm:h-8 flex justify-center items-center border-2 border-primary rounded-full
    }

    .btn-round-fill {
        @apply w-6 h-6 sm:w-8 sm:h-8 flex justify-center items-center bg-primary rounded-full text-white
    }

    .heading2 {
        @apply text-primary text-xl font-bold
    }

    .sub-heading2 {
        @apply text-2xl sm:text-4xl font-semibold mt-3
    }

    .dot {
        @apply w-3 h-3 bg-primary rounded-full
    }
}

@layer components{
    .body {
        @apply relative font-body bg-slate-50
    }

    .header {
        @apply min-h-[95vh] flex flex-col justify-end bg-secondary-100 relative 
    }

    .nav-bar{
        @apply w-full z-20 absolute top-0 pt-3 cos-container flex justify-between items-start 
    }

    .logo {
        @apply grow
    }
   
    .overlay{
        @apply z-10 h-screen w-0 fixed top-0 right-0 backdrop-blur-sm duration-100
    }

    .toggle-overlay{
        @apply w-screen 
    }
    
   
    
    .navigation {
        @apply z-10 w-60 md:w-max pl-4 py-3 fixed top-20 right-0 md:top-0 md:right-0 text-start md:relative md:grow flex flex-col md:flex-row items-end md:items-stretch md:justify-between rounded-tl-md rounded-bl-md bg-secondary-200 md:bg-transparent translate-x-full md:translate-x-0 duration-100
    }
 
    .navigation__items {
        @apply md:w-96 font-medium flex flex-col md:flex-row md:justify-between gap-y-6  
    }

    .nav__btn {
        @apply z-10 mt-3 fixed right-4 h-7 w-10 md:hidden
    }

    .toggle-navigation {
        @apply translate-x-0
    }

    .checkbox {
        @apply hidden
    }

    .hand-burger__top,
    .hand-burger__center,
    .hand-burger__bottom {
        @apply w-8 h-1 absolute block bg-primary duration-100
    }

    .hand-burger__top {
        @apply -top-2
    }

    .hand-burger__bottom {
        @apply top-2
    }

    .cross-center {
        @apply bg-transparent
    }

    .cross-top {
        @apply top-0 rotate-[135deg]
    }

    .cross-bottom {
        @apply top-0 -rotate-[135deg]
    }

    .log {
        @apply  flex gap-3 md:font-semibold
    }

    .btn-signin {
        @apply px-2 md:px-6 py-1 md:py-2 border-2 border-primary rounded-lg
    }

    .hero {
        @apply pl-0.5 sm:pl-8 md:pl-10 bscreen:pl-14 lg:pl-20 flex flex-col md:flex-row md:justify-between md:items-center
    }

    .hero__heading{
        @apply max-w-xl md:py-10 px-4 sm:px-0 text-center sm:text-start
    }

    .hero__heading--1 {
        @apply  mt-20 md:mt-0 text-5xl sm:text-6xl md:text-7xl font-semibold md:leading-[5rem]
    }

    .get-btn {
        @apply mt-3 btn-fill
    }

    .cathegories-detail {
        @apply w-[90vw] max-w-[50rem] mx-auto px-6 py-4 -translate-y-5 lg:-translate-y-1/4 xl:-translate-y-1/2 bg-white rounded-md
    }

    .cathegories-detail__heading {
        @apply text-2xl sm:text-3xl font-semibold
    }

    .cathegories-detail__items {
        @apply mt-5 flex flex-col md:flex-row md:justify-between md:items-center gap-2 [&>*]:border-2 [&>*]:border-slate-300 [&>*]:flex [&>*]:items-center [&>*]:gap-4 [&>*]:rounded-md [&>*]:px-3 [&>*]:py-1
    }

    .cathegories-detail__btn--search {
        @apply btn-fill flex justify-center
    }

    .trustee-companies {
        @apply cos-container my-10 sm:my-20
    }

    .trustee-companies__heading {
        @apply text-center font-semibold text-lg text-gry
    }

    .trustee-companies__logo {
        @apply mt-6 md:mt-12 flex flex-wrap gap-y-4 justify-center sm:justify-between
    }

    .sect-heading {
        @apply flex justify-between items-center gap-6
    }

    .slide-btns {
        @apply flex gap-3  self-end
    }

    .properties-sect {
        @apply cos-container mt-16 sm:mt-36
    }

    .properties {
        @apply mt-8 md:mt-16 flex justify-between gap-6 overflow-x-scroll md:overflow-x-hidden overscroll-x-contain [&>*]:min-w-[16rem]
    }

    .property__details {
        @apply px-3 border-2 border-t-0 border-secondary-200
    }

    .services {
        @apply mt-16 md:mt-36 py-8 px-4 sm:px-8 md:px-24 flex flex-col md:flex-row md:items-center md:justify-between gap-8 bg-secondary-100
    }

    .service__details {
        @apply mt-3 grid xs:grid-cols-2 xs:grid-rows-2 xs:justify-center xs:items-center [&>*]:flex [&>*]:items-center [&>*]:gap-3
    }

    .services__btn {
        @apply px-8 mt-5 btn-fill
    }

    .team-members {
        @apply cos-container mt-16 md:mt-28
    }

    .team-members__detail {
        @apply mt-8 md:mt-16 flex justify-between gap-6 overflow-x-scroll overscroll-x-contain md:overflow-x-hidden [&>*]:min-w-[16rem]
    }

    .reviews {
        @apply cos-container mt-16 md:mt-28
    }

    .reviewer {
        @apply mt-8 sm:mt-16 md:px-32 flex flex-col md:flex-row items-center gap-5 md:gap-10
    }

    .reviewer__name--small {
        @apply mt-5 text-lg font-semibold block md:hidden text-center
    }

    .reviewer__qoute {
        @apply max-w-xl py-3 flex flex-col md:justify-start md:items-start
    }

    .reviewer__text {
        @apply mt-5 md:mt-10
    }

    .reviewer__name--big {
        @apply mt-5 text-lg font-semibold hidden md:block
    }

    .news{
        @apply cos-container
    }

    .news__detail {
        @apply mt-8 md:mt-16 flex justify-between gap-6 overflow-x-scroll md:overflow-x-hidden overscroll-x-contain [&>*]:min-w-[16rem]
    }

    .news__date {
        @apply mt-3 flex items-center gap-3 shadow w-max
    }

    .news__headline {
        @apply mt-3 text-lg font-medium
    }

    .news__views {
        @apply mt-3 flex justify-between
    }

    .news__views--icon {
        @apply flex items-center gap-3
    }

    .news__views--btn {
        @apply px-4 py-2 bg-secondary-100 text-primary rounded-full
    }

    .subscription {
        @apply mt-16 md:mt-28 pl-4 sm:pl-8 md:pl-10 bscreen:pl-14 lg:pl-20 flex flex-col md:flex-row md:justify-between md:items-center bg-secondary-100
    }

    .subscription__heading {
        @apply sub-heading2 max-w-xl
    }

    .subscription__form {
        @apply mt-2 sm:mt-4 px-3 h-16 max-w-md flex justify-between items-center bg-white rounded-md
    }

    .footer {
        @apply cos-container h-28 flex justify-between items-center
    }

    .medias {
        @apply flex items-center gap-3 [&>*]:w-8 sm:[&>*]:w-10
    }

    /* signingIn*/

    .signing{
        @apply relative w-screen h-screen bg-secondary-100 bg-[url("./img/Signing-bg-img.png")] bg-no-repeat bg-center
    }

    .signing__content{
        @apply absolute top-0 left-0 z-20 w-full h-full grid grid-cols-2 grid-rows-1
    }

    .signing__logo{
        @apply justify-self-center self-center
    }

    .signing__form{
        @apply w-screen sm:max-w-[50vw] px-8 md:px-20 py-3 bg-white
    }
    
    .signin__form--content{
        @apply mt-10 flex flex-col gap-4 [&>*]:flex [&>*]:gap-2
    }

    .signin__form__input{
        @apply p-2 flex items-center border-[1px] border-secondary-200 rounded-md
    }

    .signing__btn{
        @apply p-2 text-white bg-primary flex justify-center rounded-md
    }

    .signing__or{
        @apply mt-8 flex justify-center items-center gap-x-7 text-xl text-[#A6A6A6;]
    }

    .signing__networks{
        @apply mt-8 flex flex-col gap-y-3
    }


    .signing__option{
        @apply mt-8 text-center text-lg font-semibold
    }

    .loading{
        @apply w-full h-full flex justify-center items-center drop-shadow-md 
    }

    .loading-spinner {
        color: official;
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .loading-spinner div {
        transform-origin: 40px 40px;
        animation: loading-spinner 1.2s linear infinite;
    }

    .loading-spinner div:after {
        content: " ";
        display: block;
        position: absolute;
        top: 3px;
        left: 37px;
        width: 6px;
        height: 18px;
        border-radius: 20%;
        background: #9e9e9e;
    }

    .loading-spinner div:nth-child(1) {
        transform: rotate(0deg);
        animation-delay: -1.1s;
    }

    .loading-spinner div:nth-child(2) {
        transform: rotate(30deg);
        animation-delay: -1s;
    }

    .loading-spinner div:nth-child(3) {
        transform: rotate(60deg);
        animation-delay: -0.9s;
    }

    .loading-spinner div:nth-child(4) {
        transform: rotate(90deg);
        animation-delay: -0.8s;
    }

    .loading-spinner div:nth-child(5) {
        transform: rotate(120deg);
        animation-delay: -0.7s;
    }

    .loading-spinner div:nth-child(6) {
        transform: rotate(150deg);
        animation-delay: -0.6s;
    }

    .loading-spinner div:nth-child(7) {
        transform: rotate(180deg);
        animation-delay: -0.5s;
    }

    .loading-spinner div:nth-child(8) {
        transform: rotate(210deg);
        animation-delay: -0.4s;
    }

    .loading-spinner div:nth-child(9) {
        transform: rotate(240deg);
        animation-delay: -0.3s;
    }

    .loading-spinner div:nth-child(10) {
        transform: rotate(270deg);
        animation-delay: -0.2s;
    }

    .loading-spinner div:nth-child(11) {
        transform: rotate(300deg);
        animation-delay: -0.1s;
    }

    .loading-spinner div:nth-child(12) {
        transform: rotate(330deg);
        animation-delay: 0s;
    }

    @keyframes loading-spinner {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
}

